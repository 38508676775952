
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
    color: #333;
}

h1, h2 {
    color: #007bff;
    text-align: center;
}

p {
    text-align: center;
}

a {
    text-decoration: none;
}

.btn-primary, .btn-secondary {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    text-align: center;
    display: inline-block;
    margin: 10px 0;
    transition: background-color 0.3s ease;
}

.btn-primary:hover, .btn-secondary:hover {
    background-color: #0056b3;
}

.hero {
    background-image: url('football.jpg');
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
}

.hero-content {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 30px;
    border-radius: 8px;
}

.hero h1 {
    font-size: 48px;
}

.hero p {
    font-size: 18px;
}

.product-section {
    padding: 50px;
    text-align: center;
    background-color: #fff;
}

.product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
}

.product-card {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.product-card img {
    width: 100%;
    border-radius: 5px;
}

.product-card h3 {
    margin: 10px 0;
}

.product-card p {
    margin: 5px 0;
    font-size: 18px;
}

.about-section {
    padding: 50px;
    background-color: #f4f4f4;
    text-align: center;
}

.about-section p {
    max-width: 800px;
    margin: 0 auto;
}

.reviews-section {
    padding: 50px;
    background-color: #fff;
    text-align: center;
}

.review {
    background-color: #f9f9f9;
    padding: 20px;
    margin: 20px auto;
    max-width: 600px;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.review h4 {
    margin-top: 10px;
    color: #555;
}

.newsletter-section {
    padding: 50px;
    background-color: #007bff;
    color: white;
    text-align: center;
}

.newsletter-section form {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.newsletter-section input {
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: none;
}

.newsletter-section button {
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
}

/* Footer */
.footer {
    padding: 20px;
    background-color: #333;
    color: white;
    text-align: center;
}

.footer .social-links a {
    margin: 0 10px;
    color: white;
}

.footer .social-links a:hover {
    text-decoration: underline;
}
