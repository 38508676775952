/* styles.css */
body {
  font-family: "Roboto", sans-serif;
  background-color: #f8f9fa;
  margin: 0;
  padding: 0;
}

.admin-panel {
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.admin-panel h1 {
  text-align: center;
  margin-bottom: 40px;
  color: #343a40;
}

.update-redirect,
.current-redirects {
  margin-bottom: 40px;
}

.update-redirect input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #ced4da;
}

.update-redirect button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.update-redirect button:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-bottom: 15px;
}

.current-redirects ul {
  list-style-type: none;
  padding: 0;
}

.current-redirects li {
  background-color: #f8f9fa;
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #ced4da;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.current-redirects li ul {
  margin: 0;
}

.copy-btn {
  padding: 4px 8px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-left: 10px;
  margin-bottom: 20px;
}

.copy-btn:hover {
  background-color: #0056b3;
}

.current-redirects li button {
  background-color: #17a2b8;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.current-redirects li button:hover {
  background-color: #138496;
}
