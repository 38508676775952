/* ErrorPage.css */
.error-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #f7f7f7;
    padding: 20px;
  }
  
  .error-code {
    font-size: 120px;
    font-weight: bold;
    color: #ff6b6b;
    margin: 0;
  }
  
  .error-message {
    font-size: 36px;
    font-weight: bold;
    color: #333;
    margin: 20px 0;
  }
  
  .error-description {
    font-size: 18px;
    color: #666;
    margin-bottom: 40px;
  }
  
  .error-button {
    background-color: #ff6b6b;
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    font-size: 16px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .error-button:hover {
    background-color: #e55b5b;
    color: white;
  }
  